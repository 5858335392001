import React, { Component } from "react"
import Fade from "react-reveal/Fade"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import $ from "jquery"

import sliderCover from "../images/autoSlider-cover.svg"
import sliderCover2 from "../images/autoSlider-cover2.svg"
import sliderCover3 from "../images/autoSlider-cover3.svg"
import sliderCover4 from "../images/autoSlider-cover4.svg"
import sliderTitleActive from "../images/sliderTitleActive.svg"

class LandSlider extends Component {
  constructor() {
    super()
    this.state = {
      sliders: [sliderCover, sliderCover2, sliderCover3, sliderCover4],
      isSlide1Active: true,
      isSlide2Active: false,
      isSlide3Active: false,
      isSlide4Active: false,
    }
  }

  componentDidMount() {
    setInterval(() => {
      this.switch()
    }, 5250)
  }
  componentWillUnmount() {
    clearInterval(this.interval)
  }

  switch = () => {
    if (this.state.isSlide1Active == true) {
      this.slider.slickGoTo([1])
      this.setState({
        isSlide1Active: false,
        isSlide2Active: true,
        isSlide3Active: false,
        isSlide4Active: false,
      })
    } else if (this.state.isSlide2Active == true) {
      this.slider.slickGoTo([2])
      this.setState({
        isSlide1Active: false,
        isSlide2Active: false,
        isSlide3Active: true,
        isSlide4Active: false,
      })
    } else if (this.state.isSlide3Active == true) {
      this.slider.slickGoTo([3])
      this.setState({
        isSlide1Active: false,
        isSlide2Active: false,
        isSlide3Active: false,
        isSlide4Active: true,
      })
    } else if (this.state.isSlide4Active == true) {
      this.slider.slickGoTo([0])
      this.setState({
        isSlide1Active: true,
        isSlide2Active: false,
        isSlide3Active: false,
        isSlide4Active: false,
      })
    }
  }

  sliders() {
    return this.state.sliders.map(data => {
      return (
        <div key={data}>
          <img alt="image" src={data} />
        </div>
      )
    })
  }

  render() {
    const settings = {
      infinite: true,
      arrows: false,
      dots: false,
      autoplay: false,
      speed: 750,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
    }
    const is1Active = this.state.isSlide1Active
    const is2Active = this.state.isSlide2Active
    const is3Active = this.state.isSlide3Active
    const is4Active = this.state.isSlide4Active
    return (
      <div className="hero is-medium" id="caracteristicas">
        <div className="hero-about">
          <div className="containerBit">
            <div className="columns columnsSlider">
              <div className="column is-5">
                <div className="autoSliderCards">
                  <Fade left delay={200}>
                    <div
                      className={
                        is1Active
                          ? "autoSliderCardItem-active"
                          : "autoSliderCardItem"
                      }
                      onClick={() => {
                        this.slider.slickGoTo([0])
                        this.setState({
                          isSlide1Active: true,
                          isSlide2Active: false,
                          isSlide3Active: false,
                          isSlide4Active: false,
                        })
                      }}
                    >
                      <p className="autoSliderCardItem-title">
                        Hecho para ti
                        {is1Active ? <img src={sliderTitleActive} /> : ""}
                      </p>
                      <p className="autoSliderCardItem-text">
                        Creado especificamente para desarrolladores
                        inmobiliarios, Bit te ayuda con los problemas a los que
                        te enfrentas a diario.
                      </p>
                      <div class="progressBar">
                        <span></span>
                      </div>
                    </div>
                  </Fade>
                  <Fade left delay={300}>
                    <div
                      className={
                        is2Active
                          ? "autoSliderCardItem-active"
                          : "autoSliderCardItem"
                      }
                      onClick={() => {
                        this.slider.slickGoTo([1])
                        this.setState({
                          isSlide1Active: false,
                          isSlide2Active: true,
                          isSlide3Active: false,
                          isSlide4Active: false,
                        })
                      }}
                    >
                      <p className="autoSliderCardItem-title">
                        Todo en uno{" "}
                        {is2Active ? <img src={sliderTitleActive} /> : ""}
                      </p>
                      <p className="autoSliderCardItem-text">
                        Haz todo tu proceso en una sola herramienta y deja de
                        malabarear con decenas de hojas de cálculo.
                      </p>
                      <div class="progressBar">
                        <span></span>
                      </div>
                    </div>
                  </Fade>
                  <Fade left delay={400}>
                    <div
                      className={
                        is3Active
                          ? "autoSliderCardItem-active"
                          : "autoSliderCardItem"
                      }
                      onClick={() => {
                        this.slider.slickGoTo([2])
                        this.setState({
                          isSlide1Active: false,
                          isSlide2Active: false,
                          isSlide3Active: true,
                          isSlide4Active: false,
                        })
                      }}
                    >
                      <p className="autoSliderCardItem-title">
                        Saca el protencial de tu equipo
                        {is3Active ? <img src={sliderTitleActive} /> : ""}
                      </p>
                      <p className="autoSliderCardItem-text">
                        Administra a tus asesores, lista de precios y comisiones
                        y mantén a todos al día.
                      </p>
                      <div class="progressBar">
                        <span></span>
                      </div>
                    </div>
                  </Fade>
                  <Fade left delay={500}>
                    <div
                      className={
                        is4Active
                          ? "autoSliderCardItem-active"
                          : "autoSliderCardItem"
                      }
                      onClick={() => {
                        this.slider.slickGoTo([3])
                        this.setState({
                          isSlide1Active: false,
                          isSlide2Active: false,
                          isSlide3Active: false,
                          isSlide4Active: true,
                        })
                      }}
                    >
                      <p className="autoSliderCardItem-title">
                        Obtén la información que necesitas
                        {is4Active ? <img src={sliderTitleActive} /> : ""}
                      </p>
                      <p className="autoSliderCardItem-text">
                        Genera gráficas y reportes para tomar mejores decisiones
                        en un solo clic.
                      </p>
                      <div class="progressBar">
                        <span></span>
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="column is-7 columnSlider">
                <div className="autoSlider">
                  <Slider ref={slider => (this.slider = slider)} {...settings}>
                    {this.sliders()}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default LandSlider
