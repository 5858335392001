import React, { Component } from "react"
import Fade from "react-reveal/Fade"
import { Link } from "gatsby"
import Layout from "../components/layout"
import LandSlider from "../components/slider"
import landCover from "../images/land-cover.png"
import SEO from "../components/seo"
import { PopupText } from "react-calendly"

function openNav() {
  const navigationWindow = document.getElementById("myNav")
  const overlayContent = document.getElementById("overlayContent")
  navigationWindow.style.opacity = 1
  navigationWindow.style.transitionDelay = "0s"
  navigationWindow.style.visibility = "visible"
  overlayContent.style.opacity = 1
  overlayContent.style.transitionDelay = ".5s"
}

function closeNav() {
  const navigationWindow = document.getElementById("myNav")
  const overlayContent = document.getElementById("overlayContent")
  overlayContent.style.opacity = 0
  overlayContent.style.transitionDelay = "0s"
  navigationWindow.style.opacity = 0
  navigationWindow.style.transitionDelay = ".5s"
  navigationWindow.style.visibility = "hidden"
}

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Bit" />
      <div className="hero is-medium">
        <div className="hero-body mainHero mainHeroCover">
          <div className="containerBit">
            <div className="columns is-hidden-mobile">
              <div className="column is-6">
                <Fade left delay={500}>
                  <h1 className="land-intro__title">
                    Administra tus ventas, clientes y a tu equipo desde un solo
                    lugar.
                  </h1>
                  <p className="land-intro__subtitle">
                    Bit es la mejor herramienta de gestión de ventas para
                    desarrollos inmobiliarios, como el tuyo.
                  </p>
                  <div className="land-intro__cta">
                    <button className="button btn-main" onClick={openNav}>
                      <strong>Empieza hoy</strong>
                    </button>
                  </div>
                </Fade>
              </div>
              <div className="column is-6 has-text-centered">
                <Fade right delay={500}>
                  <img src={landCover} className="land-intro__cover" />
                </Fade>
              </div>
            </div>
            <div className="columns is-hidden-tablet">
              <div className="column has-text-centered">
                <Fade top delay={500}>
                  <img src={landCover} className="land-intro__cover" />
                </Fade>
              </div>
              <div className="column has-text-centered">
                <Fade bottom delay={500}>
                  <h1 className="land-intro__title intro-reveal">
                    Administra tus ventas, clientes y a tu equipo desde un solo
                    lugar.
                  </h1>
                  <p className="land-intro__subtitle">
                    Bit es la mejor herramienta de gestión de ventas para
                    desarrollos inmobiliarios, como el tuyo.
                  </p>
                  <div className="land-intro__cta">
                    <button className="button btn-main" onClick={openNav}>
                      <strong>Empieza hoy</strong>
                    </button>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="landDivider" />
      <div className="hero is-medium">
        <div className="hero-body">
          <div className="container has-text-centered">
            <div className="columns ">
              <div className="column is-6 is-offset-3">
                <Fade delay={200}>
                  <p className="land-about__subtitleAlt">
                    Respira. Todo está bajo control.
                  </p>
                  <h1 className="title land-about__title">
                    Bit te ayuda a simplificar todo tu proceso de ventas, desde
                    la promoción hasta la cobranza.
                  </h1>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LandSlider />
      <div className="hero is-medium">
        <div className="hero-body mainHero">
          <div className="container has-text-centered">
            <div className="columns ">
              <div className="column is-6 is-offset-3">
                <Fade delay={200}>
                  <h1 className="title land-about__title">Con Bit puedes</h1>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hero is-medium">
        <div className="hero-body noPadTop">
          <div className="container">
            <div className="columns ">
              <div className="column has-text-centered">
                <Fade delay={200}>
                  <div className=" land-about__column">
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="land-about__icon"
                    >
                      <rect
                        x="10"
                        y="11"
                        width="20"
                        height="14.1261"
                        rx="2"
                        stroke="#1D2647"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16 29.1621H24"
                        stroke="#1D2647"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M20 25.1261V29.1621"
                        stroke="#1D2647"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div className="land-about__description">
                      <h4 className="title land-about__titleMin">
                        Integra prospectos
                      </h4>
                      <p className="land-about__text">
                        Tus prospectos de redes sociales y tu web en una sola
                        base de datos.
                      </p>
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="column has-text-centered">
                <Fade delay={400}>
                  <div className="land-about__column">
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="land-about__icon"
                    >
                      <path
                        d="M26.0014 16.0006C26.0014 12.6865 23.3149 10 20.0008 10C16.6868 10 14.0003 12.6865 14.0003 16.0006C14.0003 23.0012 11 25.0014 11 25.0014H29.0017C29.0017 25.0014 26.0014 23.0012 26.0014 16.0006"
                        stroke="#1D2647"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M21.7307 29.0018C21.3729 29.6186 20.7138 29.9982 20.0007 29.9982C19.2876 29.9982 18.6285 29.6186 18.2707 29.0018"
                        stroke="#244CD8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <div className="land-about__description">
                      <h4 className="title land-about__titleMin">
                        Recibe notificaciones
                      </h4>
                      <p className="land-about__text">
                        Recibe notificaciones improtantes para que no te pierdas
                        de nada.
                      </p>
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="column has-text-centered">
                <Fade delay={600}>
                  <div className=" land-about__column">
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="land-about__icon"
                    >
                      <path
                        d="M25 29V27C25 25.9391 24.5786 24.9217 23.8284 24.1716C23.0783 23.4214 22.0609 23 21 23H13C11.9391 23 10.9217 23.4214 10.1716 24.1716C9.42143 24.9217 9 25.9391 9 27V29"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17 19C19.2091 19 21 17.2091 21 15C21 12.7909 19.2091 11 17 11C14.7909 11 13 12.7909 13 15C13 17.2091 14.7909 19 17 19Z"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M31 29V27C30.9993 26.1137 30.7044 25.2528 30.1614 24.5523C29.6184 23.8519 28.8581 23.3516 28 23.13"
                        stroke="#244CD8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M24 11.13C24.8604 11.3503 25.623 11.8507 26.1676 12.5523C26.7122 13.2539 27.0078 14.1168 27.0078 15.005C27.0078 15.8932 26.7122 16.7561 26.1676 17.4577C25.623 18.1593 24.8604 18.6597 24 18.88"
                        stroke="#244CD8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <div className="land-about__description">
                      <h4 className="title land-about__titleMin">
                        Usuarios ilimitados
                      </h4>
                      <p className="land-about__text">
                        Desde uno a mil asesores, en Bit hay espacio para todos.
                      </p>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
            <div className="columns ">
              <div className="column has-text-centered">
                <Fade delay={200}>
                  <div className="land-about__column">
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="land-about__icon"
                    >
                      <path
                        d="M30 20H26L23 29L17 11L14 20H10"
                        stroke="#1D2647"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <div className="land-about__description">
                      <h4 className="title land-about__titleMin">
                        Monitorea a tu equipo
                      </h4>
                      <p className="land-about__text">
                        Visualiza las actividades de tu equipo en todo momento.
                      </p>
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="column has-text-centered">
                <Fade delay={400}>
                  <div className=" land-about__column">
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="land-about__icon"
                    >
                      <path
                        d="M19.6198 10V30"
                        stroke="#1D2647"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M24.303 13.6365H17.2782C15.4677 13.6365 14 15.061 14 16.8183C14 18.5756 15.4677 20.0001 17.2782 20.0001H21.9614C23.772 20.0001 25.2397 21.4247 25.2397 23.1819C25.2397 24.9392 23.772 26.3637 21.9614 26.3637H14"
                        stroke="#1D2647"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <div className="land-about__description">
                      <h4 className="title land-about__titleMin">
                        Realiza transacciones
                      </h4>
                      <p className="land-about__text">
                        Cotiza, aparta unidades, vende y cobra en un solo lugar.
                      </p>
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="column has-text-centered">
                <Fade delay={600}>
                  <div className="land-about__column">
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="land-about__icon"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M20 30C25.5228 30 30 25.5228 30 20C30 14.4772 25.5228 10 20 10C14.4772 10 10 14.4772 10 20C10 25.5228 14.4772 30 20 30Z"
                        stroke="#1D2647"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M20 14V20L24 22"
                        stroke="#244CD8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <div className="land-about__description">
                      <h4 className="title land-about__titleMin">
                        Siempre actualizados
                      </h4>
                      <p className="land-about__text">
                        Mantén actualizados tus inventarios, precios, comisiones
                        y ventas.
                      </p>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
          <div className="container has-text-centered">
            <div className="columns ">
              <div className="column is-6 is-offset-3">
                <Fade delay={200}>
                  <br />
                  <br />
                  <div className="land-intro__cta">
                    <button className="button btn-main" onClick={openNav}>
                      <strong>Comienza ahora</strong>
                    </button>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Fade delay={200}>
        <div className="hero is-medium">
          <div className="hero-body heroAlt outro">
            <div className="container has-text-centered">
              <div className="columns ">
                <div className="column is-6 is-offset-3">
                  <h1 className="title land-about__title">
                    Potencializa tus procesos y aumenta tus ventas con Bit
                  </h1>
                  <div className="land-intro__ctaAlt">
                    <button className="button btn-alpha" onClick={openNav}>
                      <strong>Solicita un demo</strong>
                    </button>
                  </div>
                  <p className="land-about__subtitleAlt">
                    <ul className="land-about__hList">
                      <li>
                        <i>✓</i> Demo Gratis
                      </li>
                      <li>
                        <i>✓</i> No requieres TDC
                      </li>
                      <li>
                        <i>✓</i> Cancela cuando quieras
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Layout>
  )
}

export default IndexPage
